import { Card, MagicLink } from ".";
import { ResponsiveImage, ResponsiveImageProps } from "../components/ResponsiveImage.component";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import "./ImageTile.style.scss";

export interface ImageTileProps {
  url: string;
  imageUrl: string;
  title: string;
  author?: string;
  responsiveImg?: ResponsiveImageProps | null;
  context?: string;
}

export const ImageTile = (props: ImageTileProps) => {
  const image = props.responsiveImg ? (
    <ResponsiveImage {...props.responsiveImg} />
  ) : (
    <img src={placeholderImage} data-src={props.imageUrl} alt={props.title} className="lazyload" />
  );
  return (
    <figure className="component-image-tile">
      <MagicLink to={props.url} target="_blank" context={props.context}>
        <Card>{image}</Card>
        <figcaption>
          <p className="title">{props.title}</p>
          {props.author ? <p className="author">{props.author}</p> : null}
        </figcaption>
      </MagicLink>
    </figure>
  );
};

export default ImageTile;
